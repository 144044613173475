import { graphql, Link } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../../../layouts/EDULayout"

export default function EDU({ data }) {
  return (
    <Layout>
      <div className="container mx-auto py-5">
        <div className="row bg-white shadow rounded-xl p-3">
          <div className="col-lg-8 align-self-center">
            <div className="h-100">
              <div className="card-body">
                <div className="d-flex justify-content-center">
                  <StaticImage
                    height={200}
                    src="../../../images/EDU_Logo.png"
                    alt="Logo of CS"
                  />
                </div>
                <div className="cardTitle">About EdSoc</div>
                <div className="card-text">
                  We Are the Home for Computer Science and Engineering Leaders.
                  <br />
                  <br />
                  The IEEE Education Society is a society of the Institute of
                  Electrical and Electronics Engineers that is focused on the
                  theory and practice of education and educational technology
                  needed to deliver effectively domain knowledge in the fields
                  of interest of IEEE.
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-4 align-self-center">
            <div className="card-body">
              <div className="cardTitle">Our Objective</div>
              <div className="card-text">
                Advances, and disseminates state-of-the-art scientific
                information and resources related to the Society's field of
                interest and provides professional development opportunities for
                academic and industry professionals.
              </div>
            </div>

            <div className="card-body">
              <div className="cardTitle">Field of Interest</div>
              <div className="card-text">
                The theory and practice of education and educational technology
                involved in the effective delivery of domain knowledge of all
                fields within the scope of interest of IEEE.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container mx-auto my-4">
        <div className="heading">
          Execom
          <div className="underline"></div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 justify-content-center">
          <div className="col d-flex flex-column align-items-center my-4">
            <StaticImage
              height={180}
              className="rounded-xl m-1"
              src="../../../images/execom/2024-25/EdSoc - Chair - Benison Abraham.jpg"
              alt="benison"
            />
            <p className="fs-5 fw-bold mb-0 mt-1">Benison Abraham</p>
            <p>Chairperson</p>
          </div>
          {/* <div className="col d-flex flex-column align-items-center my-4">
            <StaticImage
              height={180}
              className="rounded-xl m-1"
              src="../../../images/execom/2024-25/EdSoc - ViceChair - Sayuj.png"
              alt="sayuj"
            />
            <p className="fs-5 fw-bold mb-0 mt-1">Sayuj S</p>
            <p>Vice Chairperson</p>
          </div> */}

          <div className="col d-flex flex-column align-items-center my-4">
            <StaticImage
              height={180}
              className="rounded-xl m-1"
              src="../../../images/execom/2024-25/EdSoc - Secretary - Abhai PC.jpg"
              alt="abhai"
            />
            <p className="fs-5 fw-bold mb-0 mt-1">Abhai PC</p>
            <p>Secretary</p>
          </div>
          <div className="col d-flex flex-column align-items-center my-4">
            <StaticImage
              height={180}
              className="rounded-xl m-1"
              src="../../../images/execom/2024-25/EdSoc - WR - Devapriya.jpg"
              alt="Devapriya"
            />
            <p className="fs-5 fw-bold mb-0 mt-1">Devapriya K</p>
            <p>Women in Representative</p>
          </div>
        </div>
      </div>

      <div className="container my-5">
        <div className="heading">
          Events
          <div className="underline"></div>
        </div>
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 align-items-center justify-content-center my-4">
          {data.allMarkdownRemark.edges?.map(el => (
            <Link
              key={el.node.frontmatter.title}
              className="col card my-2 shadow"
              to={`../../reg/${el.node.frontmatter.slug}`}
              style={{ maxWidth: "18rem" }}
            >
              <div>
                <GatsbyImage
                  image={getImage(el.node.frontmatter.image)}
                  alt={el.node.frontmatter.title}
                  className="card-img"
                />
              </div>
              <div className="card-body d-flex felx-column center">
                <div className="fw-bold text-center text-dark">{el.node.frontmatter.title}</div>
              </div>
            </Link>
          ))}
        </div>
        <div className="d-flex justify-content-center my-4">
          <Link className="btn btn-primary" to="/reg">
            View More Events
          </Link>
        </div>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query edu {
    allMarkdownRemark(
      filter: { frontmatter: { by: { regex: "/EDSOC/i" } } }
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          frontmatter {
            title
            date
            slug
            by
            image {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  }
`
